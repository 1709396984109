import React from 'react'
import Ankle from './Ankle'
import Footer from './Footer'
import SectionHeader from './SectionHeader'
import BreadCrumbs from './BreadCrumbs'
import './SectionTemplate.scss'

const SectionTemplate = ({title, path, image, showBreadCrumbs = true, children}) => {
  return (
    <div className='SectionTemplate'>
      <SectionHeader>
      </SectionHeader>
      {path && <BreadCrumbs sectionTitle={title} path={path} />}
      <div className="section-header">
        <img src={`/assets/chestnuttree.png`} alt={title}/>
        <div className="headers">
          <h1>{title}</h1>
        </div>
      </div>
      <div className="crib">
        {children}
      </div>
      <Ankle>
      </Ankle>
      <Footer>
      </Footer>
    </div>
  )
}

export default SectionTemplate