import React from 'react'
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import Accordion from '../components/Accordion'
import Card from '../components/Card'
import CardContainer from '../components/CardContainer'
import Carousel from '../components/Carousel'
import FactBox from '../components/FactBox'
import SectionTemplate from '../components/SectionTemplate'
import ShowMore from '../components/ShowMore'
import Slide from '../components/Slide'

function Section({pageContext}) {
  const shortcodes = { Accordion, FactBox, Carousel, Slide, Card, CardContainer, ShowMore }
  const { section } = pageContext

  return (
    <div className="Section">
      <SectionTemplate title={section.frontmatter.sectionTitle} path={section.slug}>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {section.body}
          </MDXRenderer>
        </MDXProvider>
      </SectionTemplate>
    </div>
  )
}

export default Section;